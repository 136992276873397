var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"referral-period-statistics"},[_c('a-card',{attrs:{"size":"small"}},[_c('div',{staticClass:"flex flex-col md:flex-row gap-2"},[_c('select-input',{staticClass:"order-2 md:order-1 mt-0 mb-0 pb-0 w-full",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.referralPeriodStatisticLogic,
            'key': 'period',
            'prefix': 'statistic_',
            'options': _vm.periodGroupOptions,
            'clearable': false,
            'supportButtons': false
          }
        }},on:{"input":function($event){return _vm.referralPeriodStatisticLogic.getStatisticsByPeriod()}}}),_c('set-period',{staticClass:"order-1 md:order-2",on:{"searchPeriod":function($event){return _vm.referralPeriodStatisticLogic.getStatisticsByPeriod()}},model:{value:(_vm.referralPeriodStatisticLogic.dates),callback:function ($$v) {_vm.$set(_vm.referralPeriodStatisticLogic, "dates", $$v)},expression:"referralPeriodStatisticLogic.dates"}})],1)]),_c('a-table',{staticClass:"mt-5",attrs:{"columns":_vm.columns,"data-source":_vm.referralPeriodStatisticLogic.data,"row-key":function (r) { return r.user.user_id; },"scroll":{ x: true },"loading":_vm.referralPeriodStatisticLogic.isLoading,"pagination":{
      total: _vm.referralPeriodStatisticLogic.total,
      'show-size-changer': true,
      'show-quick-jumper': true,
      'page-size-options': [ '10', '20', '30' ]
    }},on:{"change":function ($event) { return _vm.referralPeriodStatisticLogic.handleChange($event); }},scopedSlots:_vm._u([{key:"user-info",fn:function(text, record){return _c('user-main-info',{attrs:{"name":record.user.name,"login":record.user.login,"avatar":record.user.avatar}})}},{key:"chart",fn:function(text, record){return _c('vue-apex-charts',{staticStyle:{"border-bottom":"1px solid #e8e8e8"},attrs:{"type":"bar","height":"80","series":record.chart.series,"options":_vm.columnOptions}})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }