


























import ReferralsTable from "@/views/chat/statistics/components/referrals/referralsTable.vue";
import TopReferrals from "@/views/chat/statistics/components/referrals/topReferrals.vue";
import { ReferralsRawData } from "@/includes/types/statistics/referrals";
import StaticDataApi from "@/includes/Api/StaticData.api";
import { errorNotification } from "@/includes/NotificationService";

import { Vue, Component } from 'vue-property-decorator';
import moment from "moment";
import { CanceledError } from "axios";

@Component({
  components: {
    TopReferrals,
    ReferralsTable
  },
  methods: {
    moment
  }
})
export default class ReferralTotalStatistics extends Vue {
  rawData: ReferralsRawData | null = null

  isLoading = false

  referrals: ReferralsRawData['users'] | null = null

  abortController: AbortController | null = null

  getTotalStat(): void {
    const statAccessKey = this.$store.state.chatState.chat?.stat_access_key

    if (statAccessKey) {
      this.isLoading = true

      this.abortController = new AbortController()

      StaticDataApi.getChatReferralsStat(statAccessKey, { signal: this.abortController.signal })
        .then(res => {
          // this.rawData = require('./data.json')
          this.rawData = res.data
          this.referrals = res.data.users.sort((a, b) => b.referrals - a.referrals)
        })
        .catch((error) => {
          if (error instanceof CanceledError) {
            return
          }

          errorNotification(error.response ?? error.message)
        })
        .finally(() => {
          this.isLoading = false
        })
    } else {
      this.isLoading = false
    }
  }

  created() {
    this.getTotalStat()
  }

  destroyed() {
    if (this.abortController) {
      this.abortController.abort()
    }
  }
}
