import { ReferralsRawData } from "@/includes/types/statistics/referrals";

import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

import axios, { AxiosRequestConfig } from "axios";

export default class StaticDataApi {
  static async getChatUsersStat(statAccessKey: string) {
    return axios(`${ PostApi.getBaseHostnameApi() }/static/privatechatmembers/${ statAccessKey }_34.json`)
  }

  static async getChatReferralsStat(statAccessKey: string, config?: AxiosRequestConfig) {
    return axios.get<ReferralsRawData>(`${ PostApi.getBaseHostnameApi() }/static/privatechatreferrals/${ statAccessKey }_34.json`, config)
  }

  static async getReputationStat(statAccessKey: string) {
    return axios.get(`${ PostApi.getBaseHostnameApi() }/static/privatereputation/data${ statAccessKey }.json`)
  }

  static async getChatReferrals(chatId: number) {
    return axios.get(`${ PostApi.getBaseHostnameApi() }/static/privatechatreferrals/${ chatId }_34.json`)
  }
}
