
































































import SetPeriod from "@/views/chat/statistics/components/mainStatistics/setPeriod.vue";
import { convertLabels } from "@/includes/utils/statistics";
import { ReferralPeriodStatisticLogic } from "@/includes/logic/statistics/referrals/period";
import { StatisticPeriodEnum } from "@/includes/types/statistics/statistics.types";

import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue';
import { SelectOption } from "piramis-base-components/src/logic/types";
import { ApexChartLocales } from "piramis-base-components/src/shared/types/ApexChartSeries.types";

import { Component, Mixins } from 'vue-property-decorator';
import VueApexCharts from "vue-apexcharts";
import { ApexOptions } from "apexcharts";

@Component({
  components: {
    SetPeriod,
    VueApexCharts,
    UserMainInfo,
    SelectInput,
  }
})
export default class ReferralPeriodStatistics extends Mixins(ModelSetter) {
  referralPeriodStatisticLogic = new ReferralPeriodStatisticLogic()

  get periodGroupOptions():Array<SelectOption> {
    return [
      {
        label: this.$t(`statistics_group_day`).toString(),
        value: StatisticPeriodEnum.Day
      },
      {
        label: this.$t(`statistics_group_week`).toString(),
        value: StatisticPeriodEnum.Week
      },
      {
        label: this.$t(`statistics_group_month`).toString(),
        value: StatisticPeriodEnum.Month
      },
      {
        label: this.$t(`statistics_group_year`).toString(),
        value: StatisticPeriodEnum.Year
      },
    ]
  }

  get columns() {
    return [
      {
        title: this.$t('stat_users_data_table_col_user'),
        width: 250,
        key: 'user-info',
        dataIndex: 'user',
        scopedSlots: { customRender: 'user-info' },
      },
      {
        title: this.$t('statistics_total_title'),
        width: 100,
        dataIndex: 'total',
        key: 'total',
      },
      {
        title: this.$t('users_statistics_table_referrals'),
        key: 'chart',
        dataIndex: 'chart',
        scopedSlots: { customRender: 'chart' },
      },
    ]
  }

  get columnOptions(): ApexOptions {
    return {
      chart: {
        type: 'bar',
        animations: {
          enabled: false
        },
        sparkline: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
        defaultLocale: this.$i18n.locale,
        locales: ApexChartLocales,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        show: false,
        padding: {
          right: 0,
          left: 0,
          bottom: 0,
          top: 0
        }
      },
      xaxis: {
        labels: {
          show: false,
        },
        categories: this.referralPeriodStatisticLogic.ticks ? convertLabels(this.referralPeriodStatisticLogic.ticks.timeTicks) : [ ],
      },
      yaxis: {
        min: 0,
        max: this.referralPeriodStatisticLogic.maxValue,
        show: false
      },
      tooltip: {
        marker: {
          show: false
        },
        y: {
          formatter: (val: number, opts?: any): string => {
            return val.toFixed(0)
          },
          title: {
            formatter: (seriesName: string): string => ''
          }
        }
      }
    }
  }

  created() {
    this.referralPeriodStatisticLogic.getStatisticsByPeriod()
  }

  destroyed() {
    this.referralPeriodStatisticLogic.abortRequest()
  }
}
