




















































import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import PermissionsBanner from "@/components/PermissionsBanner.vue";
import { SitePermissionEnum } from "@/includes/types/AddAdmin/types";
import ReferralPeriodStatistics from "@/components/statistic/referrals/ReferralPeriodStatistic.vue";
import ReferralTotalStatistics from "@/components/statistic/referrals/ReferralTotalStatistic.vue";

import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import PiTabs from "piramis-base-components/src/components/PiTabs.vue";
import CenteredColumnLayout from "piramis-base-components/src/components/CenteredColumnLayout.vue";
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";

import { Component, Mixins } from 'vue-property-decorator'

enum StatisticPeriodTabEnum {
  Total = 'Total',
  Period = 'Period',
}

@Component({
  components: {
    ReferralTotalStatistics,
    ReferralPeriodStatistics,
    PermissionsBanner,
    PageTitle,
    NotAvailableOptionsOverlay,
    PiTabs,
    CenteredColumnLayout
  },
  data() {
    return {
      SitePermissionEnum,
      StatisticPeriodTabEnum
    }
  }
})
export default class StatisticsReferrals extends Mixins<TariffsTagsHelper>(TariffsTagsHelper) {

  currentTab: StatisticPeriodTabEnum = StatisticPeriodTabEnum.Total

  get tabs(): Array<SelectOptionData> {
    return [
      {
        label: this.$t('statistic_referrals_tab_total').toString(),
        value: StatisticPeriodTabEnum.Total,
        icon: {
          name: 'calendar_today'
        }
      },
      {
        label: this.$t('statistic_referrals_tab_period').toString(),
        value: StatisticPeriodTabEnum.Period,
        icon: {
          name: 'calendar_month'
        }
      },
    ]
  }

  changeTab(tab: StatisticPeriodTabEnum) {
    this.currentTab = tab
  }
}
