import { PeriodUserRecord, TableDataRecord } from "@/includes/types/statistics/referrals";
import { tickUnitByPeriod, updateDateToStart } from "@/includes/utils/statistics";
import StatisticsApi from "@/includes/Api/Statistics.api";
import { errorNotification } from "@/includes/NotificationService";
import store from "@/store/store";

import { fromToTicks, FromToTicksReturnType } from "piramis-base-components/src/shared/utils/fromToTicks";

import { CanceledError } from "axios";
import moment from "moment";
import { StatisticPeriodEnum } from "@/includes/types/statistics/statistics.types";

export class ReferralPeriodStatisticLogic {
  total = 1

  dates = {
    from: moment().add(-1, 'months').format('YYYY-MM-DD'),
    to:  moment().format('YYYY-MM-DD')
  }

  data: Array<TableDataRecord> | null = null

  ticks: FromToTicksReturnType | null = null

  page = 1

  limit = 10

  maxValue: number | undefined = undefined

  isLoading = false

  private abortController: AbortController | null = null

  period: StatisticPeriodEnum = StatisticPeriodEnum.Week

  updateTicks() {
    this.ticks = this.getFromToTicks()
  }

  getFromToTicks() {
    const { from, to } = updateDateToStart(this.dates, this.period)

    return fromToTicks(from, to, { unit: tickUnitByPeriod(this.period) })
  }

  abortRequest() {
    if (this.abortController) {
      this.abortController.abort()
    }
  }

  //https://1x.antdv.com/components/table/#API api here
  handleChange(pagination: any) {
    if (this.page !== pagination.current) {
      this.page = pagination.current

      this.getStatisticsByPeriod()

      return
    }

    if (this.limit !== pagination.pageSize) {
      this.limit = pagination.pageSize

      this.getStatisticsByPeriod()

      return
    }
  }

  async getStatisticsByPeriod() {
    this.isLoading = true

    this.abortController = new AbortController()

    try {
      const { data } = await StatisticsApi.getReferralStatisticsByPeriod(store.getters.chatId,
          {
            from: this.dates.from,
            to: this.dates.to,
            period: this.period,
            limit: this.limit,
            offset: (this.page - 1) * this.limit
          },
          { signal: this.abortController.signal })

      this.updateTicks()

      this.maxValue = Math.max(...Object.values(data.data[0]?.data ?? []))
      this.total = data.count

      this.data = await Promise.all(data.data.map(d => this.getMetricData(d, this.ticks!)))

    } catch(error: any) {
      if (error instanceof CanceledError) {
        return
      }

      errorNotification(error.response ?? error.message)
    } finally {
      this.isLoading = false
    }
  }

  async getMetricData(record: PeriodUserRecord, ticks: FromToTicksReturnType): Promise<TableDataRecord> {
    const { total, data, ...user } = record

    return Promise.resolve({
      total,
      user,
      chart: {
        series: [ { name: 'referrals', data: Object.values(Object.assign({ ...ticks.mapTimeData }, data)) } ]
      }
    });
  }
}
